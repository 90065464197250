
import './App.css';

//Dependencies 
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"; 

//Components Import 
import Home from "./components/Home";
import PocketWalletPrivacy from "./components/PocketWalletPrivacy";
import EpicDancePrivacy from "./components/EpicDancePrivacy"; 

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
        <Route path="/" exact component={Home}/> 
          <Route path="/pocketwalletprivacy" component={PocketWalletPrivacy}/>
        <Route path="/epic-dance-privacy" component={EpicDancePrivacy}/>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
