import React from "react"
import "../App.css";

import appMainScreen from "../assets/images/pocketwallethome.jpeg"  
import phoneImage from "../assets/images/phone-img.png"
import epicDanceIcon from "../assets/images/icon.png"

const Home = () => {
    return (
        <div className="home-container "> 
            <section class="colored-container bg-primary" id="title">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 class="logo">Epic Dance <img class="icon-png" src={epicDanceIcon} alt="epicdance-icon"/></h1>
                        </div>
                        <div class="col-lg-6">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 class="big-heading">Loose yourself with energetic beats.</h1> 
                            <a href="" style={{textDecoration:"none"}}>
                                    <button type="button" class="btn btn-dark btn-lg download-button"><i class="fa-brands fa-apple"></i>
                                        Download</button>
                                </a>
                            {/* <button type="button" class="btn btn-outline-light btn-lg download-button"><i
                                class="fa-solid fa-envelope"></i> Contact Us</button> */}
                        </div>
                        <div class="col-lg-6">
                            <img class="title-image" src={phoneImage} alt="iphone-mockup"/>
                        </div>
                    </div>
                    </div>
            </section>
            <div className="display-section home-container ">
                <img src={appMainScreen}
                    className="app-screen"
                    alt="App Main Screen"
                />
                <div className="download-section">
                    <h3 className="white-font subtitle ">Live your dream lifestyle, save with</h3>
                    <h1 className="title white-font rochester">Pocket Wallet</h1>
                    <h3 className="white-font ">Download now</h3>
                    <a className="app-store-anchor" href="https://apps.apple.com/ca/app/pocketwallet-budget-app/id1564610616">
                        <div className="app-store-button">
                            <i className="fab fa-apple"></i>
                            <h4 className="app-button-text"> App store </h4>
                        </div>
                    </a>
                </div>
            </div>
            <div className="contact-us">
                <h2 className="white-font">We appreciate your feedback, contact us</h2> */}
                <a href="mailto:pocketxapps@gmail.com"> <h3 className="white-font app-store-anchor" style={{ textDecoration: "none" }}>pocketxapps@gmail.com</h3></a>
            </div>

        </div>
    )
}

export default Home;  