import React from 'react'

const EpicDancePrivacy = () => {
  return (
    <div className="privacy-page bg-light">
            <h1 className="privacy-title">EPIC DANCE PRIVACY</h1>
            <h2 className="privacy-title">Privacy Policy</h2>
            <div className="policy-section">
                <p className="privacy-paragraph">
                    “You” referring to the user and “us” referring to PocketXApps as a company and app referring to EpicDance. 
                </p>
                <p className="privacy-paragraph">
                    This Privacy Policy is subject to change without notification to the user.
                </p>
            </div>

            <div className="policy-section">
                <h2 className="section-title ">
                    PERSONAL DATA
                </h2>
                <p className="privacy-paragraph">
                    We do not collect any of your personal data other than your device Advertising I.D. in order for third party advertisement providers to present to you personalized ads. 
                    This is optional and you can choose not to provide us with your advertising ID by going to your phone's privacy settings. 
                </p>
            </div>


            <div className="policy-section">
                <h2 className="section-title ">
                    USE OF YOUR INFORMATION
                </h2>
                <p className="privacy-paragraph">
                   Use of your information is for advertisement tracking
                </p>

            </div>


            <div className="policy-section">
                <h2 className="section-title ">
                    THIRD PARTY SERVICE PROVIDER
                </h2>
                <p className="privacy-paragraph">
                   Please see personal data section
                </p>
            </div>
            <div className="policy-section">
                <h2 className="section-title ">
                    CONTACT US
                </h2>
                <p className="privacy-paragraph">
                    If you have questions or comments about this Privacy Policy, please contact us at:
                </p>
                <a href="mailto: pocketxapps@gmail.com ">pocketxapps@gmail.com</a>
            </div>
        </div>
  )
}

export default EpicDancePrivacy