import React from "react"
import "../App.css";

const PocketWalletPrivacy = () => {

    return (
        <div className="privacy-page bg-light">
            <h1 className="privacy-title">Pocket Wallet</h1>
            <h2 className="privacy-title">Privacy Policy</h2>
            <div className="policy-section">

                <p className="privacy-paragraph">
                    “You” referring to the user and “us” referring to PocketXApps as a company and app referring to PocketWallet. Social auth providers referring to signing-up using Facebook, Apple, or Google

                </p>
            </div>

            <div className="policy-section">
                <h2 className="section-title ">
                    PERSONAL DATA
                </h2>
                <p className="privacy-paragraph">
                    Security of your data is important to us. We only collect your email address provided by you when signing-up to Pocket Wallet or email obtained when signing-up through Apple, Facebook, or Google.
                </p>
                <p className="privacy-paragraph">
                    The app may ask for your first name or last name for identifying you within the app.
                </p>
                <p className="privacy-paragraph">
                    Future versions of the app may have the opportunity to include a profile picture that you can either upload from your device or obtain through your social auth provider. The profile image will be optional and can be deleted from the App database.
                </p>
            </div>


            <div className="policy-section">
                <h2 className="section-title ">
                    USE OF YOUR INFORMATION
                </h2>
                <p className="privacy-paragraph">

                    The email you provided will be used for signing-up and using the app as an authenticated user.
                </p>
                <p className="privacy-paragraph">
                    We may send you emails about saving tips, new updates about PocketWallet, contests, and promotions of PocketWallet and other apps created by PocketXApps. You can unsubscribe to stop receiving emails.
                </p>
            </div>


            <div className="policy-section">
                <h2 className="section-title ">
                    THIRD PARTY SERVICE PROVIDER
                </h2>
                <p className="privacy-paragraph">
                    We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, database service, and marketing assistance.
                </p>
            </div>

            <div className="policy-section">
                <h2 className="section-title ">
                    DATA DELETION
                </h2>
                <p className="privacy-paragraph">
                    You can delete all personal data you inputted in the app within the app and all user data can be deleted by deleting your account.
                </p>
                <p className="privacy-paragraph">
                    Deleting your account removes all your data from the app’s database and can no longer be used or retrieved in the future. This includes data from social auth providers.
                </p>
                <p className="privacy-paragraph">
                    This Privacy Policy is subject to change without notification to the user.
                </p>
            </div>

            <div className="policy-section">
                <h2 className="section-title ">
                    CONTACT US
                </h2>
                <p className="privacy-paragraph">
                    If you have questions or comments about this Privacy Policy, please contact us at:
                </p>
                <a href="mailto: pocketxapps@gmail.com ">pocketxapps@gmail.com</a>
            </div>
        </div>
    )
}

export default PocketWalletPrivacy;


